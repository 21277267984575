import React, {useEffect, useRef, useState, useLayoutEffect} from 'react'
import ModalDialog from '../modal-dialog'
import {useDispatch, useSelector} from 'react-redux'
import LocationPin from '../svg/LocationPin'
import LocationPinSingle from '../svg/LocationPinSingle'
import LocationPinPoint from '../svg/LocationPinPoint'
import DestinationPin from '../svg/DestinationPin'
import Icon from '../icon'
import { RemovecloseF } from '../../icon-library'
import {Alert, Button, Type} from "mesh-component-library"
import {generatePath} from './../../utilities/RedirectUtils'
import {
  disabledVehicleIcon,
  selectedTowDestinationIcon,
  selectedTowDestinationIconPepBoys,
  towDestinationOptionIcon,
  towDestinationOptionIconPepBoys
} from '../../app-consts/mapConstants'

import {
  clearCurrentLocation,
  clearDestination,
  clearDisablementAddress,
  clearNearByDestinations,
  clearTowToCustomDestinationList,
  removeDestinationAddress,
  requestGoogleNearbySearch,
  updateDestinationWithPredictionAddress,
  addGooglePlaceIdToDestination,
  requestAdditionalNearByDestinationsForDropdown,
  updateRescueDetails, refreshReducersToIntial
} from '../../action'
import Spinner from "../spinner"
import {useHistory} from 'react-router-dom'
import {CANADA, TOW, TOW_SERVICE_ID} from '../../app-consts/appConstants'
import {datadogRum} from '@datadog/browser-rum'
import SlideArrow from '../svg/SlideArrow'
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import RightDirectArrow from '../svg/RightDirectArrow'
import titleCase from '../../utils/titleCase'
import {getPredictiveETA, saveRescueDetails} from '../../api/api'
import {getOmniStreamlinedFlag, getServiceRequestPayload} from '../../selector'
import { isEmpty } from 'lodash'
import { formatMinutesToTimeRange } from '../../utils/timeUtil'
import { isScheduleService } from '../../utils/serviceRequestPayloadUtils'
import { partialSortArrayByCondition } from '../../utils/partialSortArrayByCondition';
import { DropdownListMember } from './ListMember';

const google = window.google;

let destinationMarkers = []

const GoogleMap = (props) => {
  const {
    mapOptions,
    openInvalidAddressModal,
  } = props
  const history = useHistory();
  const dispatch = useDispatch();
  const nearByDestinationAutoCompleteService = new google.maps.places.AutocompleteService()

  const currentLocation            = useSelector(state => state.currentLocation)
  const serviceRequestPayload      = useSelector(getServiceRequestPayload);
  const nearByDestinations         = useSelector(state => state.nearByDestinations)
  const selectedDestinationDetails = useSelector(state => state.destination)
  const serviceInfo                = useSelector(state => state.serviceInfo)
  const rescueDetailsSent          = useSelector(state => state.generalInfo.rescueDetailsSent || false)
  const partnerDetails             = useSelector(state => state.partnerDetails.partnerDetails)
  const customDestinationList      = useSelector(state => state.generalInfo.towToCustomDestinationList)
  const omniStreamlined = useSelector(getOmniStreamlinedFlag)
  const {displayCode, appId, phone, partnerCode, experience: {towToEnabled, supportedRegions, customDestinationEnabled, passThruEnabled, towToLocationType}} = partnerDetails

  const [showSpinner, setShowSpinner] = useState(false)
  const [destinationName, setDestinationName] = useState(selectedDestinationDetails.formatted_address || '')
  const [displayNearByDestinationsDropdown, setDisplayNearByDestinationsDropdown] = useState(!!nearByDestinations)
  const [displayNoDestinationsFound, setDisplayNoDestinationsFound] = useState(false)
  const [currentLocationAddress, setCurrentLocationAddress] = useState(currentLocation.formatted_address)
  const [currentLocationMarker, setCurrentLocationMarker] = useState(null)
  const [destinationPredictions, setDestinationPredictions] = useState([])
  const [displayDestinationPredictions, setDisplayDestinationPredictions] = useState(false)
  const [customTowToDestinationPredictions, setCustomTowToDestinationPredictions] = useState(customDestinationList)
  const [displayCustomTowToDestinationPredictions, setDisplayCustomTowToDestinationPredictions] = useState(false)
  const [destinationMarkerFromSelectedPrediction, setDestinationMarkerFromSelectedPrediction] = useState(false)
  const [destinationInputFocus, setDestinationInputFocus] = useState(0)
  const [noRepairCentersFoundText, setNoRepairCentersFoundText] = useState("")
  const [noTowDestinationFound, setNoTowDestinationFound] = useState(false)
  const [noTowCentersFoundText, setNoTowCentersFoundText] = useState("")
  const [map, setMap] = useState(null)
  const [propsArrowIcon, setPropsArrowIcon] = useState({})
  const getTranslatedText = useLocale();
  const [predictiveETA, setPredictiveETA] = useState({});
  const isAMCJJ = partnerCode === "AMC" || partnerCode === "JJ"

  let nearByDestinationIcon = towDestinationOptionIcon
  let selectedDestinationIcon = selectedTowDestinationIcon
  if(displayCode === 'PEP'){
    nearByDestinationIcon = towDestinationOptionIconPepBoys
    selectedDestinationIcon = selectedTowDestinationIconPepBoys
  }

  const destinationInputRef = useRef()

  useEffect(() => {
    let map = new google.maps.Map(mapRef.current, mapOptions)

    google.maps.event.addListenerOnce(map, 'idle', () => {
      setMap(map);
    });

    generateModalText()
    if(omniStreamlined && !rescueDetailsSent && serviceInfo.service === 'tow') {
      saveRescueDetails(serviceRequestPayload, partnerCode, '', history, (()=> dispatch(refreshReducersToIntial())))
      dispatch(updateRescueDetails(true))
    }
  }, [])

  useLayoutEffect(() => {
    if(map){
      resetMapBoundaries()
    }
  }, [map, currentLocation, selectedDestinationDetails, nearByDestinations])

  useEffect(() => {
    if(!map){
      return
    }

    if(currentLocation.formatted_address){
      setCurrentLocationAddress(currentLocation.formatted_address)
      if(currentLocationMarker){
        currentLocationMarker.setMap(null)
      }
      setCurrentLocationMarker(createMarker(disabledVehicleIcon, currentLocation.geometry.location));

      if(serviceInfo.id === TOW_SERVICE_ID && !nearByDestinations?.destinations?.length) {
        setShowSpinner(true);
        searchForNearByDestinations(currentLocation.geometry?.location);
      }
    }
  }, [map, currentLocation])

  useEffect(() => {
    if(!map){
      return
    }

    if(nearByDestinations?.error && !Object.keys(selectedDestinationDetails).length) {
      setDisplayNoDestinationsFound(true)
    }
    else {
      let newMarkerArray = []
      if (nearByDestinations?.destinations?.length) {
        nearByDestinations.destinations.forEach((destination) => {
          let marker = createNearByDestinationMarker(nearByDestinationIcon, destination);
          newMarkerArray.push(marker)
        })
        destinationMarkers = newMarkerArray
      }
    }

    if (nearByDestinations?.error || nearByDestinations?.destinations?.length) {
      setShowSpinner(false)
    }
  },[map, nearByDestinations])

  useEffect(() => {
    if(!map){
      return
    }

    if(selectedDestinationDetails?.error){
      openInvalidAddressModal()
      setShowSpinner(false)
    }

    if(destinationMarkerFromSelectedPrediction){
      destinationMarkerFromSelectedPrediction.setMap(null)
    }

    if(!selectedDestinationDetails.formatted_address){
      setDestinationName('')
      destinationMarkers.forEach((marker) => {
        marker.setMap(map)
        marker.setIcon(nearByDestinationIcon)
      })
    }

    if(selectedDestinationDetails.formatted_address) {
      handleUpdatedSelectedDestination(selectedDestinationDetails)
    }

  }, [map, selectedDestinationDetails])

  useEffect(() => {
    if (destinationInputFocus) {
      destinationInputRef?.current.focus();
    }
  }, [destinationInputFocus])

  useEffect(() => {
    let iconValues = {
      className: 'active deleteDropoff arrowDown',
      color: "focus",
      direction:'up',
      role: 'down-arrow-svg'
    }

    if(!displayNearByDestinationsDropdown) {
      iconValues.direction = 'down'
      iconValues.rotate = 'rotate'
    }

    setPropsArrowIcon(iconValues)

  }, [displayNearByDestinationsDropdown])

  useEffect(async () => {
    if (!isScheduleService(serviceRequestPayload)) {
      const predictiveETAResult = await getPredictiveETA({currentLocation, isTow: serviceInfo.value === TOW, appId: partnerDetails.appId, sessionId: serviceRequestPayload?.sessionId, partnerCode: partnerDetails.partnerCode});
      if (predictiveETAResult) {
        setPredictiveETA(predictiveETAResult);
      }
    }
  }, [])

  const handleUpdatedSelectedDestination = (selectedDestinationDetails) => {
    const {formatted_address,name} = selectedDestinationDetails
    let displayName = formatted_address.includes(name) ? formatted_address : name + ", " + formatted_address
    if(showSpinner){
      setShowSpinner(false)
    }
    setDestinationName(displayName)
    setDisplayNearByDestinationsDropdown(false)
    setDisplayDestinationPredictions(false)

    updateSelectedDestinationIcon()
  }

  const resetMapBoundaries = () => {
    const bounds = new google.maps.LatLngBounds()

    if(currentLocation.geometry) {
      bounds.extend(currentLocation.geometry.location);
    }

    if(selectedDestinationDetails.geometry){
      bounds.extend(selectedDestinationDetails.geometry.location);
    }

    if(nearByDestinations?.destinations?.length){
      nearByDestinations.destinations.forEach((destination) => {
        bounds.extend(destination.geometry.location);
      })
    }

    map.fitBounds(bounds);

    if(!currentLocation.geometry && !selectedDestinationDetails.geometry && !nearByDestinations?.destinations?.length) {
      let lat = supportedRegions[0]===CANADA ? 56.1304 : 39.82835
      let lng = supportedRegions[0]===CANADA ? -106.3468 : -98.5816684
      map.setCenter({lat, lng});
      map.setZoom(4);
    }
    else if(currentLocation.geometry && !selectedDestinationDetails.geometry && !nearByDestinations?.destinations?.length){
      map.setZoom(16);
    }
    else if(currentLocation.geometry && selectedDestinationDetails.geometry && !nearByDestinations?.destinations?.length){
      map.setZoom(map.getZoom() - 1.5);
    }
  }

  const updateSelectedDestinationIcon = () => {
    if(destinationMarkers.length){
      destinationMarkers.forEach((marker) =>{
        if(marker.placeId === selectedDestinationDetails.place_id){
          marker.setIcon(selectedDestinationIcon)
        } else {
          if(selectedDestinationDetails.hasBeenConfirmed){
            marker.setMap(null)
          } else {
            marker.setIcon(nearByDestinationIcon)
          }
        }
      })
    }

    let marker = createNearByDestinationMarker(selectedDestinationIcon, selectedDestinationDetails);
    setDestinationMarkerFromSelectedPrediction(marker)
  }

  const sortPreferredForAMCJJ = () => {
    const checkIsDealerPreferred = (customDestination) => customDestination.isPreferredDealer === true

    const locations = nearByDestinations.destinations.map(destination => {
      const customDestination = customDestinationList.filter(d => d.dealerId === destination.reference)[0]
      return customDestination ? { ...destination, isPreferredDealer: checkIsDealerPreferred(customDestination) } : destination
    })

    return partialSortArrayByCondition({ array: locations.slice(), condition: (location) => location.isPreferredDealer === true })
  }

  const createMarker = (icon, latLng, label, placeId) => {
    let marker = new google.maps.Marker({
      position: latLng,
      map,
      icon: icon,
    });
    marker.placeId = placeId
    return marker
  }

  const createNearByDestinationMarker = (icon, destination) => {
    const marker = createMarker(icon, destination.geometry.location, destination.name, destination.place_id)
    marker.addListener("click", () => {
      if(towToEnabled){
        let value = destination.name + ', ' + titleCase(destination.vicinity);
        selectCustomTowToLocationFromPredictions(value)
      } else {
        selectNewDestinationFromPredictions(destination.place_id)
      }
    })
    return marker
  }

  const searchForNearByDestinations = (location) => {
    let service = new google.maps.places.PlacesService(map);
    const searchParams = {
      location,
      placeService: service,
      displayCode,
      appId
    }
    dispatch(requestGoogleNearbySearch(searchParams));
  }

  const displayNearByDestinationList = () => {
    if(!displayDestinationPredictions && !nearByDestinations.error){
      setDisplayNearByDestinationsDropdown(true)
    }
    if (towToEnabled && customDestinationEnabled && passThruEnabled) {
      setDisplayCustomTowToDestinationPredictions(false)
    }
  }

  const deleteDestinationInput = () => {
    setDisplayDestinationPredictions(false)
    setDisplayCustomTowToDestinationPredictions(false)
    !nearByDestinations.error && setDisplayNearByDestinationsDropdown(true)
    setDestinationName('')
    destinationMarkers.forEach((marker)=>marker.setMap(map))
    dispatch(clearDestination())
    dispatch(removeDestinationAddress())
  }

  const resetCurrentLocation = () => {
    destinationMarkers.forEach((marker)=>marker.setMap(null))
    destinationMarkers = []
    dispatch(clearDestination());
    dispatch(removeDestinationAddress())
    dispatch(clearNearByDestinations());
    dispatch(clearTowToCustomDestinationList())
    dispatch(clearCurrentLocation());
    dispatch(clearDisablementAddress())
    history.push(generatePath('disablementlocation'));
  }

  const selectNewDestinationFromPredictions = (placeId) => {
    setDisplayDestinationPredictions(false)
    let service = new google.maps.places.PlacesService(map);
    const data = {service, placeId, origin: currentLocation.geometry.location}

    setDestinationName('')
    destinationMarkers.forEach((marker)=>marker.setMap(null))
    setShowSpinner(true)
    dispatch(updateDestinationWithPredictionAddress(data))
  }

  const selectCustomTowToLocationFromPredictions = (query) => {
    setShowSpinner(true)
    setDisplayCustomTowToDestinationPredictions(false)
    let service = new google.maps.places.PlacesService(map);
    dispatch(addGooglePlaceIdToDestination({query, service, origin: currentLocation.geometry.location}))
  }

  const getDestinationPredictions = ({target: {value}}) => {
    try{
      setDisplayNearByDestinationsDropdown(false)
      setDestinationName(value)

      let bounds = new google.maps.LatLngBounds(map.getBounds().getSouthWest(), map.getBounds().getNorthEast())
      const predictionsRequest = {
        input: value,
        componentRestrictions: {country: 'us'},
        origin: currentLocation.geometry.location,
        bounds
      }

      const displaySuggestions = (predictions, status) => {
        if (value.length > 2 && status === google.maps.places.PlacesServiceStatus.OK) {
          predictions.sort((a, b) => (a.distance_meters > b.distance_meters) ? 1 : -1)
          setDestinationPredictions(predictions)
          setDisplayDestinationPredictions(true)
        } else {
          setDisplayDestinationPredictions(false)
        }
      };

      nearByDestinationAutoCompleteService.getPlacePredictions(predictionsRequest, displaySuggestions);
    } catch(error){
      datadogRum.addError(new Error(`GoogleMap error in getDestinationPredictions - error: ${error}, address: ${value}`));
    }
  }

  const dropDownOverlayClick = () => {
    setDisplayNearByDestinationsDropdown(false)
  }

  const mapRef = useRef()
  const createMapTemplate = () => {
    return(
      <div className="l-grid">
        <div className="l-grid__col l-grid__col--12">
          <div className='l-grid fullheight'>
            <div
              className="l-grid__col l-grid__col--12"
              id={'service-map'}
              ref={mapRef}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderCurrentAddressFormField = () => {
    return (
      <div className="dropoffGroup">
        <input name="disablement-address"
               autoComplete="off"
               id="formField"
               type="text"
               className={'placename'}
               placeholder={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION)}
               aria-label={'Current Location'}
               value={currentLocationAddress}
               disabled/>
        {currentLocationAddress &&
          <Icon role="link"
                ariaLabel="Delete Disablement Address"
                tabIndex="0"
                icon={RemovecloseF}
                color="gray-darker"
                className={'active deletePlace'}
                onClick={resetCurrentLocation}
                onKeyDown={resetCurrentLocation}
          size={1}
        />
        }
      </div>
    )
  }

  const closeNoLocationModalHandler = () => {
    setDisplayNoDestinationsFound(false)
    setDestinationInputFocus(destinationInputFocus + 1)
  }

  const displayAdditionalDestinations = () => {
    if(nearByDestinations?.error && !Object.keys(selectedDestinationDetails).length) {
      setDisplayNoDestinationsFound(true)
    } else{
      setDisplayNearByDestinationsDropdown(!displayNearByDestinationsDropdown)
    }
  }

  const getTowText = () => {
    if (isAMCJJ) {
      return getTranslatedText(
        TRANSLATION_CONSTANTS.SELECT_OR_ENTER_TOW_DESTINATION
      );
    } else if (towToEnabled) {
      return getTranslatedText(TRANSLATION_CONSTANTS.SELECT_TOW_LOCATION);
    } else {
      return getTranslatedText(
        TRANSLATION_CONSTANTS.SELECT_OR_ENTER_TOW_DESTINATION
      );
    }
  }

  const renderDestinationInputFormField = () => {
    return (
      <div className="dropoffGroup">
        <div onClick={displayNearByDestinationList}>
          <input autoComplete="off"
               id="destinationFormField"
               ref={destinationInputRef}
               type="text"
               className='dropname'
               aria-label='Search for new destination'
               placeholder={getTowText()}
               value={destinationName}
               onChange={getDestinationPredictions}
               disabled={towToEnabled && !customDestinationEnabled}/>
        </div>
        {
          nearByDestinations && !destinationName &&
          <SlideArrow {...propsArrowIcon} onClick={() => displayAdditionalDestinations()} />
        }
        {
          destinationName &&
          <Icon role="link"
                ariaLabel="Delete Destination Address"
                tabIndex="0"
                icon={RemovecloseF}
                color="gray-darker"
                className={'active deleteDropoff'}
                onClick={deleteDestinationInput}
                onKeyDown={deleteDestinationInput}
                size={1}/>
        }
      </div>
    )
  }

  const renderTowToDestinationSearchableFormField = () => {
    return (
      <div className="dropoffGroup">
        <div onClick={displayNearByDestinationList}>
          <input autoComplete="off"
                 id="destinationFormField"
                 ref={destinationInputRef}
                 type="text"
                 className={'dropname'}
                 aria-label={'Search for new destination'}
                 placeholder={getTowText()}
                 value={destinationName}
                 onChange={onValueChanged}
                 />
        </div>
        {
          (nearByDestinations && !destinationName) &&
          (<SlideArrow {...propsArrowIcon} onClick={() => displayAdditionalDestinations()} />)
        }
        {
          destinationName &&
          <Icon role="link"
                ariaLabel="Delete Destination Address"
                tabIndex="0"
                icon={RemovecloseF}
                color="gray-darker"
                className={'active deleteDropoff'}
                onClick={deleteDestinationInput}
                onKeyDown={deleteDestinationInput}
                size={1}/>
        }
      </div>
    )
  }

  const closeNoTowLocationModalHandler = () => {
    setNoTowDestinationFound(false)
    setDestinationName("")
    setDisplayNearByDestinationsDropdown(true)
  }
  const onValueChanged = ({target: {value}}) => {
    if (value.length > 2) {
      setDisplayNearByDestinationsDropdown(false)

      const pattern = new RegExp(`${value}`, 'i')
      const suggestions = customDestinationList.filter((location) => location.searchableName.match(pattern))

      if (suggestions.length > 0) {
        setNoTowDestinationFound(false);
        setDisplayCustomTowToDestinationPredictions(true)
        setCustomTowToDestinationPredictions(suggestions)
      } else {
        setNoTowDestinationFound(true);
        setDisplayNearByDestinationsDropdown(false)
        setDisplayCustomTowToDestinationPredictions(true)
        setCustomTowToDestinationPredictions([])
        setNoTowCentersFoundText(getTranslatedText(TRANSLATION_CONSTANTS.NO_SUGGESTIONS));
      }
    } else {
      setDisplayNearByDestinationsDropdown(true)
      setDisplayCustomTowToDestinationPredictions(true)
      setCustomTowToDestinationPredictions([])
    }
    setDestinationName(value)
  }

  const renderCustomTowToPredictionsDropdownList = (towToDestinationPredictions) => {
    return (
      <div className={'softService c-currentOptions'}>
        <div className="c-currentPredictions">
          <div className="c-currentPredictions__itemcontain">
            {
              towToDestinationPredictions.map((towToPrediction, i) => {
                return (
                  <a tabIndex="0"
                     key={i}
                     className="c-currentPredictions__item"
                     onClick={() => selectCustomTowToLocationFromPredictions(towToPrediction.searchableName)}>
                    <LocationPinPoint/>
                    <p className="u-text-truncate">{towToPrediction.searchableName}</p>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }


  const renderPredictionsDropdownList = (predictions, setNewLocation) => {
    return (
      <div className={`${serviceInfo.id !== TOW_SERVICE_ID ? 'softService' : ''} c-currentOptions`}>
        <div role="link"
             tabIndex="0"
             className="c-currentOptions__disablement">
          <DestinationPin/>
          <p>Select from these suggestions...</p>
        </div>
        <div className="c-currentPredictions">
          <div className="c-currentPredictions__itemcontain">
            {
              predictions.map((prediction, i) => {
                return (
                  <a tabIndex="0"
                     key={i}
                     className="c-currentPredictions__item"
                     onClick={() => setNewLocation(prediction.place_id)}
                     onKeyDown={(e) => {
                       const active = document.activeElement;
                       if (e.keyCode === 13) {
                         setNewLocation(prediction)
                       } else if (e.keyCode === 38 &&
                         active.previousSibling) {
                         active.previousSibling.focus();
                       } else if (e.keyCode === 40 && active.nextSibling) {
                         active.nextSibling.focus();
                       }
                     }}>
                    <LocationPinPoint/>
                    <p className="u-text-truncate">{prediction.description}</p>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  const selectNewDestinationValue = (destination) => {
    if(towToEnabled){
      let value = destination.name + ', ' + titleCase(destination.vicinity);
      selectCustomTowToLocationFromPredictions(value)
    } else{
      selectNewDestinationFromPredictions(destination.place_id)
    }
  }

  const renderNearbyPreferredDestinationsDropdownList = () => sortPreferredForAMCJJ().map((destination, i) => <DropdownListMember index={i} onClick={selectNewDestinationValue} destination={destination} />)

  const renderNearByDestinationsDropdownList = () => nearByDestinations?.destinations?.map((destination, i) => <DropdownListMember index={i} onClick={selectNewDestinationValue} destination={destination} />)

  const renderDestinationDropdownList = () => {
    let service = new google.maps.places.PlacesService(map);
    return (
      <div className="dropoffModal">
        {towToLocationType ? renderNearbyPreferredDestinationsDropdownList() : renderNearByDestinationsDropdownList()}
        {
          (nearByDestinations?.destinations?.length <
            nearByDestinations?.places?.length) && (
            <div className="see-more-main" onClick={() =>
              dispatch(
                requestAdditionalNearByDestinationsForDropdown({
                  service,
                  destinations: nearByDestinations?.destinations,
                })
              )
            }>
              <span className="see-more-text">
                <span>{getTranslatedText(TRANSLATION_CONSTANTS.SEE_MORE)}</span>
              </span>
              <span className="see-more-icon">
                <RightDirectArrow />
              </span>
            </div>
          )
        }
      </div>
    )
  }

  const renderInputFieldPins = () => {
    const pinColor = selectedDestinationDetails.formatted_address ? '#0033A0' : '#B9C6D3'
    const locationPinColor = currentLocation.formatted_address ? '#0033A0' : '#B9C6D3'
    return (
      serviceInfo.id === TOW_SERVICE_ID ?
        <LocationPin pinColor={pinColor} locationColor={locationPinColor}/>
        :
        <LocationPinSingle locationColor={locationPinColor}/>
    )
  }

  const generateModalText = () => {
    if(displayCode === 'GM') {
      setNoRepairCentersFoundText(`Sorry. Can't find a dealer. Please call customer service.`)
      return;
    }
    if(customDestinationEnabled && !passThruEnabled) {
      setNoRepairCentersFoundText(getTranslatedText(TRANSLATION_CONSTANTS.ENTER_MANUAL_LOCATION));
    } else {
      setNoRepairCentersFoundText(getTranslatedText(TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER, displayCode));
    }
  }

  const callForHelp = () => {
    datadogRum.addAction(`CallForHelp-NoRepairCentersFound-${appId}`);
    const callbackPhone = phone || localStorage.getItem('callbackNumber');
    window.location.href = 'tel:' + callbackPhone;
  }

  const getPredictiveETAAlertMessage = () => {
    const {petaFrom, petaTo} = formatMinutesToTimeRange(serviceInfo.value === TOW, predictiveETA);
    return getTranslatedText(TRANSLATION_CONSTANTS.PETA_ALERT).replace("{{petaFrom}}", petaFrom).replace("{{petaTo}}", petaTo);
  }

  return (
    <div className="c-google-map" id="location-map">
      {showSpinner && <Spinner/>}
      {createMapTemplate()}
      {
        displayNearByDestinationsDropdown &&
        <div role="figure"
             tabIndex="0"
             className="c-google-map--overlay"
             onClick={dropDownOverlayClick}
             onKeyDown={dropDownOverlayClick}/>
      }
      {
        displayNoDestinationsFound &&
        <ModalDialog id="locationError"
                     title={getTranslatedText(TRANSLATION_CONSTANTS.NO_REPAIR_CENTERS_FOUND)}
                     promptType="error"
                     hideTrigger={true}
                     isActive={true}
                     onClose={closeNoLocationModalHandler}>
          <p>{noRepairCentersFoundText}</p>
          <div className="l-grid">
              {customDestinationEnabled && !passThruEnabled &&
              <div className="l-grid__col l-grid__col--12">
                <a className="c-btn c-btn--primary" onClick={closeNoLocationModalHandler}>
                  Enter Location
                </a>
                </div>
            }
              <Button
                size='lg'
                hasUpgrade
                onClick={() => callForHelp()}
                onKeyDown={() => callForHelp()}
                utils={{
                  fullWidth: true
                }}>
                {getTranslatedText(TRANSLATION_CONSTANTS.CALL_CUSTOMER_SERVICE)}
              </Button>
          </div>
        </ModalDialog>
      }
      {
        noTowDestinationFound &&
        <ModalDialog id="locationError"
                     title="No Tow Destination found"
                     promptType="error"
                     hideTrigger={true}
                     isActive={true}
                     onClose={closeNoTowLocationModalHandler}>
          <p>{noTowCentersFoundText}</p>
          <div className="l-grid">
              <div className="l-grid__col l-grid__col--12">
                <a className="c-btn c-btn--primary" onClick={closeNoTowLocationModalHandler}>
                  Try Again
                </a>
              </div>
            <div className="l-grid__col l-grid__col--12">
              <a className="c-btn c-btn--primary" href={`tel: ${phone}`}>
                {getTranslatedText(TRANSLATION_CONSTANTS.CALL_CUSTOMER_SERVICE)}
              </a>
            </div>
          </div>
        </ModalDialog>
      }

      <div className={'dropNameContain'}>
        <div className="dropNameContain--internal">
          {renderInputFieldPins()}
          <div>
            {renderCurrentAddressFormField()}
            {(() => {
              if (serviceInfo.id === TOW_SERVICE_ID){
                if(!towToEnabled || (customDestinationEnabled && !passThruEnabled)){
                  return renderDestinationInputFormField()
                } else{
                  return renderTowToDestinationSearchableFormField()
                }
              }
            })()}
          </div>
        </div>

        {displayDestinationPredictions && renderPredictionsDropdownList(destinationPredictions, selectNewDestinationFromPredictions)}
        {displayNearByDestinationsDropdown && renderDestinationDropdownList()}
        {displayCustomTowToDestinationPredictions && renderCustomTowToPredictionsDropdownList(customTowToDestinationPredictions)}
      </div>
      {!isScheduleService(serviceRequestPayload) && !isEmpty(predictiveETA) &&
        <Alert
          className={serviceInfo.value === TOW ? "petaAlert" : "petaAlert petaAlert--nonTow"}
          variant="information"
          size="sm"
        >
          <Type align="left" className="petaAlertText">
            {getPredictiveETAAlertMessage()}
          </Type>
        </Alert>
      }
    </div>
  )
}

export default GoogleMap
