import {takeEvery, call, put} from 'redux-saga/effects';
import {
  loadFeatureFlag,
  loadFeatureFlagFailed,
  loadFeatureFlagSuccess
} from "../action";
import {getOmniStreamlinedFlag} from "../api";

function* onInit() {

  const [_ , route, partnerCode, trackerPartnerCode] = window.location.pathname.split("/");
  const values = new URLSearchParams(window.location.search);
  const aniValue = values.get('ANI')
  let sessionRoute;
  if(!!sessionStorage.getItem('route')) {
    sessionRoute = sessionStorage.getItem('route');
  }

  yield put(loadFeatureFlag({route, partnerCode}))

  try {
    let response
    if (sessionRoute === 'member' && route === 'wd' && partnerCode === 'l') {
      response = yield call(getOmniStreamlinedFlag, trackerPartnerCode);
    } else if ((route === 'appointment-confirmation' && partnerCode === undefined) ||
      (route === 'wd' && partnerCode === 'l' && !aniValue)) {
      yield put(loadFeatureFlagSuccess("")); // Default to empty value for wd
      return;
    } else if (route === 'wd' && partnerCode === 'l' && aniValue) {
      response = yield call(getOmniStreamlinedFlag, trackerPartnerCode);
    } else {
      response = yield call(getOmniStreamlinedFlag, partnerCode);
    }

    yield put(loadFeatureFlagSuccess(response.data)); // Dispatch success action with the response data
  } catch(error) {
    yield put(loadFeatureFlagFailed(error))
  }



}

export function* watchInitSaga() {
  yield takeEvery("@@App/INIT", onInit);
}
