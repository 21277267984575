import {takeEvery, put, call, select, all, takeAll, take} from 'redux-saga/effects';
import {delay} from 'redux-saga/effects';
import {
  COMMON_BENEFITS_START,
  COMMON_BENEFITS_FAILED,
  BENEFITS_LOOKUP_START,
  commonBenefitsFailed,
  noCommonBenefits,
  commonBenefitsSuccess,
  updatePartnerDetailsExperience,
  updateSessionId,
  updatePpuFlag,
  updateLoggedInFlag,
  updateServiceDetails,
  updateServiceTransition,
  updateDuplicateRescueIsActive,
  updatePromoCode,
  updatePartnerMemberId,
  updateUsageId,
  updateBusinessName,
  updateRentalDetail
} from '../action';
import {
  getCommonBenefits,
  getPartnerDetails,
  getServiceRequestPayload,
  getServiceTransition,
  getOmniStreamlinedFlag
} from '../selector';
import {
  createMemberSessionOpenApi,
  fetchCommonBenefitsForUser,
  fetchBenefits,
  searchRescue,
  sendJacadaRequest,
  fraudMonitor
} from '../api';
import CONSTANTS, {
  ACTIVE_MEMBERSHIP_STATUSES,
  BENEFITS_USED_UP,
  CALLBACK_NUMBER_RETURNED_MEMBERSHIP_STATUSES,
  CONTRACT_CANCELLED,
  CONTRACT_EXPIRED,
  CONTRACT_NOT_ACTIVE_YET,
  INACTIVE,
  LAST_NAME_AUTHENTICATION_CHECK_LOOKUP_TYPES,
  LAST_NAME_AUTHENTICATION_CHECK_PARTNERS,
  MATCH_FAILURE,
  MEMBER_NOT_FOUND_STATUS,
  MEMBER_NOT_FOUND_VAS,
  MULTIPLE_MEMBERS_RETURNED,
  NOT_COVERED_REASONS,
  REIMBURSEMENT,
  VAS_DG4180,
  VAS_DG4090,
  VAS_DG3180,
  VAS_DG3090,
  WAIT_PERIOD_STATUS,
  JJ_AND_AMC
} from '../app-consts/appConstants';
import {
  isCreatedNoContentStatus,
  is4xxClientError,
  is5xxServerError,
} from "../utils/apis";
import {ErrorSource} from "@datadog/browser-core";
import {datadogRum} from "@datadog/browser-rum";
import trimmedLengthIsTen from '../utils/phoneLength';
import {
  MEMBER_NOT_FOUND,
  UNABLE_TO_PROCESS_REQUEST,
} from "../app-consts/errorMessageConstants";
import { experimentalMonitor } from '../utils/experimentalMonitorUtil';
import {isEmpty} from "lodash";
import _isEmpty from "lodash/isEmpty";
import {generatePathWithSearchParams} from "../utilities/RedirectUtils";

function logAndReturn500Failure(sessionId) {
  datadogRum.addError(new Error(`Benefits API returned status 500 for session ID ${sessionId}.`), undefined, ErrorSource.NETWORK);
  return put({type: COMMON_BENEFITS_FAILED});
}

function logAndReturnMaxAttemptsFailure(sessionId) {
  datadogRum.addError(new Error(`Benefits API hit max attempts for session ID ${sessionId}.`), undefined, ErrorSource.CUSTOM);
  return put(noCommonBenefits({message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE, response: {}}));
}

function logAndCheckAmcMilesFlow({ amcCoverageAmount, coveredMiles }) {
  if (amcCoverageAmount && parseInt(amcCoverageAmount) === 9999990 && coveredMiles && coveredMiles !== "999999" && parseInt(coveredMiles) > 0) {
    localStorage.setItem('amcMilesFlow', 'true');
    datadogRum.addRumGlobalContext('usr', {
      amcMilesFlow: 'true'
    })
  } else {
    localStorage.removeItem('amcMilesFlow');
  }
}

function logAndReturnOutOfClaims(benefits, omniStreamlined) {
  let newErrorMessage = !!omniStreamlined && NOT_COVERED_REASONS.find(pair => pair.id === benefits.status?.toUpperCase())
  let errorReason = newErrorMessage?.reason;
  if (!!newErrorMessage && newErrorMessage !== CONSTANTS.OUT_OF_CLAIMS_MESSAGE) {
    datadogRum.addAction(`Customer has ${errorReason} for the partner: ${benefits.partnerCode}`)
  } else {
    datadogRum.addAction("Benefits Used Up")
    datadogRum.addRumGlobalContext('usr', {
      claims: 'Out of claims'
    });
    errorReason = CONSTANTS.OUT_OF_CLAIMS_MESSAGE;
  }
  return put(noCommonBenefits({message: errorReason, response: benefits}));
}

function logAndReturnSuccess({ sessionId: session, partnerCode, appId, responseBody, type, phone }) {
  datadogRum.addAction("Benefits Available")
  datadogRum.addRumGlobalContext('usr', {
    session,
    memberId: responseBody.memberId,
    partnerCode,
    appId,
    conversationId: localStorage.getItem('conversationId') || 'unavailable',
    jobId: localStorage.getItem('jobId') || 'unavailable',
    ani: phone || 'unavailable'
  });
  localStorage.setItem('sessionType', type);
  return put(commonBenefitsSuccess(responseBody));
}

function generateVehicleEventDataObj(equipmentClassId) {
  if (equipmentClassId === "Light") {
    return {
      'name': 'equpmnt_clss_id',
      'value': "1"
    };
  } else if (equipmentClassId === "Medium") {
    return {
      'name': 'equpmnt_clss_id',
      'value': "2"
    };
  } else if (equipmentClassId === "Heavy") {
    return {
      'name': 'equpmnt_clss_id',
      'value': "3"
    };
  } else {
    return {
      'name': 'equpmnt_clss_id',
      'value': equipmentClassId
    };
  }
}

function generateJacadaEventsObj({ memberId, partnerCode, promoCode, sessionId, phone, authenticated, equpmnt_clss_id }) {
  let eventsData = [];
  eventsData.push(
    {
      'name': 'mbr_id',
      'value': authenticated === 'Y' ? memberId : ''
    },
    {
      'name': 'partnr_cd',
      'value': partnerCode
    },
    {
      'name': 'disblmnt_prmotn_cd',
      'value': promoCode
    },
    {
      'name': 'sessionId',
      'value': sessionId
    },
    {
      'name': 'original_ani_nbr',
      'value': phone
    },
    {
      'name': 'job_id',
      'value': localStorage.getItem('jobId')
    }
  );

  if (authenticated === 'Y') {
    eventsData.push(
      {
        'name': 'authenticated',
        'value': authenticated
      });
  }

  if(equpmnt_clss_id) {
    eventsData.push(generateVehicleEventDataObj(equpmnt_clss_id));
  }
  return eventsData;
}

function* logJacadaEvent({ memberId, partnerCode, promoCode, sessionId, phone, authenticated, equpmnt_clss_id }) {
  try {
    const conversationId = localStorage.getItem('conversationId') || 'unavailable';
    const jacadaResponse = yield call(sendJacadaRequest, {
      externalSystemId: conversationId,
      events: generateJacadaEventsObj({
        memberId: authenticated === 'Y' ? memberId : '',
        partnerCode,
        promoCode,
        sessionId,
        phone,
        authenticated,
        equpmnt_clss_id
      })
    });
    if (jacadaResponse.status === 200) {
      datadogRum.addAction(`${partnerCode}-jacada-authentication`, {
        authenticated: authenticated
      })
    } else {
      const error = new Error(`Jacada returned ${jacadaResponse.status} status for Conversation ID: ${conversationId}, Request: ${JSON.stringify(jacadaResponse)}.`)
      datadogRum.addError(error, undefined, ErrorSource.CUSTOM)
    }
  } catch (e) {
    datadogRum.addError(new Error(`Jacada event failure ${e}`), undefined, ErrorSource.CUSTOM)
  }
}

export function* commonBenefits(action) {
  const commonBenefits = yield select(getCommonBenefits);
  const partnerDetails = yield select(getPartnerDetails);
  const serviceRequestPayload = yield select(getServiceRequestPayload);

  let memberId = action.payload.memberId || commonBenefits.memberId;
  let rentalAgreementNumber = action.payload.rentalAgreementNumber;
  let vin = action.payload.vin || commonBenefits.vin;
  let mileage = action.payload.mileage;
  let existingSessionId = action.payload.sessionId;
  let phoneLookup = action.payload.phoneLookup;
  let lastName = action.payload.lastName ? action.payload.lastName : null;
  if (phoneLookup && trimmedLengthIsTen(serviceRequestPayload.serviceRequest.contactNumber)) {
    memberId = serviceRequestPayload.serviceRequest.contactNumber;
  }
  const lookupType = action.payload.lookupType;
  let { partnerCode, promoCode, route, appId } = partnerDetails;
  const type = "wle-client";

  const details = action.payload.details;
  const arsToken = action.payload.arsToken;
  const createSessionRequest = action.payload.formdata ? action.payload.formdata : null;
  let phone = serviceRequestPayload.serviceRequest.contactNumber;

  let authenticated = "N";
  let retryBenefits = true;
  let retryBenefitsCounter = 0;
  let sessionId = '';
  let equpmnt_clss_id = '';
  let sessionResponse = '';

  let completeResponse;

  try {
    // NOTE: using redux-effect's call function will not accept a plain object hence the options construction here <?!>
    const options = {
      memberId,
      partnerCode,
      appId,
      type,
      createSessionRequest,
      phoneLookup,
      details,
      vin,
      mileage,
      arsToken,
      lastName,
      rentalAgreementNumber
    };
    if(!existingSessionId) {
      if (memberId) {
        const searchRescueResponse = yield call(searchRescue, partnerCode, appId, lookupType, memberId);
        if (searchRescueResponse.status === 200) {
          const { usageId = "", duplicateRescueIronDoor = {}} = searchRescueResponse.data
          experimentalMonitor(duplicateRescueIronDoor)(
            "Denominator",
            { usageId },
            "duplicateRescueIronDoor"
          );
          yield put(updateDuplicateRescueIsActive({
            usageId,
            duplicateRescueIronDoor
          }))
          return;
        }
      }
      sessionResponse = yield call(createMemberSessionOpenApi, route, options);
      if (sessionResponse.status === 200) {
        sessionId = sessionResponse.data.id;
        localStorage.setItem('benefitSession', sessionId);
        if (sessionId) {
          yield put(updateSessionId(sessionId));
        }
      } else if (sessionResponse.status === 400 && sessionResponse.data?.message?.toLowerCase() === 'invalid oauth token for supplied client id.') {
        yield put(commonBenefitsFailed({
          message: CONSTANTS.INVALID_OAUTH_TOKEN
        }))
        return;
      } else if (sessionResponse.status === 400) {
        if(LAST_NAME_AUTHENTICATION_CHECK_PARTNERS.includes(partnerCode) && LAST_NAME_AUTHENTICATION_CHECK_LOOKUP_TYPES.includes(lookupType)) {
          datadogRum.addAction(`Session API returned 400 response for ${sessionResponse.data?.message}, memberId: ${memberId}, lastName: ${lastName}, sessionId: ${sessionId}`)
        } else {
          datadogRum.addError(new Error(`Session API returned 400 response for AppID: ${appId} / Partner Code: ${partnerCode} / LookupType: ${lookupType}`), undefined);
        }
        yield put(noCommonBenefits({
          message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
          response: {}
        }))
        return;
      } else {
        datadogRum.addError(new Error(`Session API returned non-2xx status for AppID: ${appId} / Partner Code: ${partnerCode}.`), undefined);
        yield put(commonBenefitsFailed());
        return;
      }
    } else {
      sessionId = existingSessionId
      yield put(updateSessionId(sessionId))
    }
    while (retryBenefits && retryBenefitsCounter < 20) {
      completeResponse = yield call(fetchCommonBenefitsForUser, sessionId, sessionResponse.data?.appId ? sessionResponse.data?.appId : appId, sessionResponse.data?.partnerCode ? sessionResponse.data?.partnerCode : partnerCode);
      const commonUserBenefitsData = completeResponse.data;
      if (completeResponse.status === 200) {
        if (retryBenefitsCounter === 19) {
          retryBenefits = false;
          yield logAndReturnMaxAttemptsFailure(sessionId);
        } else if (ACTIVE_MEMBERSHIP_STATUSES.includes(commonUserBenefitsData.status?.toUpperCase()) ||
          commonUserBenefitsData.status?.toUpperCase() === "COMPLETED") {
          retryBenefits = false;
          datadogRum.addAction(`Lookup Successful ${lookupType}`)
          promoCode = commonUserBenefitsData.promoCode;
          memberId = commonUserBenefitsData.memberId || '';
          if(existingSessionId && lookupType === "uuidLookup" && memberId){
            const searchRescueResponse = yield call(searchRescue, partnerCode, appId, lookupType, memberId);
            if (searchRescueResponse.status === 200) {
              const { usageId = "", duplicateRescueIronDoor = {}} = searchRescueResponse.data
              experimentalMonitor(duplicateRescueIronDoor)(
                "Denominator",
                { usageId },
                "duplicateRescueIronDoor"
              );
              yield put(updateDuplicateRescueIsActive({
                usageId,
                duplicateRescueIronDoor
              }))
              return;
            }
          }
          localStorage.setItem('memberId', memberId);
          authenticated = "Y";
          if (commonUserBenefitsData.vehicles?.[0]?.equipmentClass) {
            equpmnt_clss_id = commonUserBenefitsData.vehicles[0].equipmentClass;
          }

          yield * manageBenefitData({benefitsData: commonUserBenefitsData, partnerDetails, sessionId, type, phone})

          logAndCheckAmcMilesFlow(commonUserBenefitsData);

        } else if (commonUserBenefitsData.status?.toUpperCase() === "CANCELLED") {
          datadogRum.addAction(`Lookup Successful ${lookupType}`)
          datadogRum.addAction(`Benefits Status Cancelled`)
          retryBenefits = false;
          yield all([
            put(updatePpuFlag("Y")),
            put(noCommonBenefits({
              message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
              response: {}
            }))
          ])
        } else if (commonUserBenefitsData.status?.toUpperCase() === "MUST_CALL_FOR_WEIGHT_CLASS") {
          datadogRum.addAction(`Lookup Successful ${lookupType}`)
          datadogRum.addAction(`Benefits Status Must Call For Weight Class`)
          retryBenefits = false;
          yield put(noCommonBenefits({
            message: CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS,
            response: {}
          }));
        } else if (commonUserBenefitsData.status?.toUpperCase() === WAIT_PERIOD_STATUS) {
          datadogRum.addAction(`Lookup Successful ${lookupType}`)
          datadogRum.addAction(`Benefits Status Wait Period`)
          retryBenefits = false;
          yield all([
            put(updatePpuFlag("Y")),
            put(noCommonBenefits({
              message: CONSTANTS.WAIT_PERIOD,
              response: {}
            }))
          ])
        } else if ((commonUserBenefitsData.experience?.serviceBased && commonUserBenefitsData.status?.toUpperCase() === CONSTANTS.INACTIVE)
          || commonUserBenefitsData.status?.toUpperCase() === CONSTANTS.NO_MEMBER_FOUND) {
          retryBenefits = false;
          yield all([
            put(
              updateSessionId(completeResponse?.data?.callId)
            ),
            put(
              updateUsageId(completeResponse?.data?.usageId)
            ),
            put(updateServiceTransition({
              errorMessageContent: MEMBER_NOT_FOUND,
            })),
            put(noCommonBenefits({
              message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
              response: {}
            }))
          ])
        } else if (commonUserBenefitsData.status?.toUpperCase() === CONSTANTS.MUTLIPLE_MEMBERS_FOUND) {
          retryBenefits = false;
          yield all([
            put(
              updateSessionId(completeResponse?.data?.callId)
            ),
            put(
              updateUsageId(completeResponse?.data?.usageId)
            ),
            put(
              updateServiceTransition({
              errorMessageContent: MEMBER_NOT_FOUND,
            })),
            put(
              noCommonBenefits({
                message: CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE,
                response: {}
            }))])
        }
        if ( CALLBACK_NUMBER_RETURNED_MEMBERSHIP_STATUSES.includes(commonUserBenefitsData.status?.toUpperCase())
          && phoneLookup
          && commonUserBenefitsData.callbackNumber !== null
          && commonUserBenefitsData.callbackNumber !== ""
          && commonUserBenefitsData.callbackNumber !== undefined) {
          localStorage.setItem('callbackNumber', commonUserBenefitsData.callbackNumber)
        }
      } else if (isCreatedNoContentStatus(completeResponse.status) || is4xxClientError(completeResponse.status)) {
        datadogRum.addAction(`Lookup Not Successful ${lookupType}`)
        let reasonFor4xx = completeResponse?.data?.message || '';
        datadogRum.addAction(`Lookup Not Successful Reason: ${reasonFor4xx}`)
        retryBenefits = false;
        let errorMessage = CONSTANTS.MEMBER_NOT_FOUND_MESSAGE;
        if(completeResponse?.data?.message === CONSTANTS.INVALID_MVA_NUMBER){
          errorMessage = CONSTANTS.INVALID_MVA_NUMBER;
        }
        yield all([
          put(updatePpuFlag("Y")),
          put(noCommonBenefits({
            message: errorMessage,
            response: {}
          }))
        ])
      } else if (is5xxServerError(completeResponse.status)) {
        datadogRum.addAction(`Lookup Not Successful ${lookupType}`)
        let reasonFor5xx = completeResponse?.data || '';
        datadogRum.addAction(`Lookup Not Successful Reason: ${JSON.stringify(reasonFor5xx)}`)
        retryBenefits = false;
        datadogRum.addError(new Error(`Benefits API returned status 500 for session ID ${sessionId}.`), undefined)
        yield logAndReturn500Failure(sessionId);
      }
      retryBenefitsCounter++;
      yield delay(1000); // wait 1 second before retry logic
    }
  } catch (e) {
    if (is4xxClientError(e?.response?.status)) {
      const error = new Error(`Benefits API returned status 400 for session ID ${sessionId}.`);
      datadogRum.addError(error, ErrorSource.NETWORK)
    }
    else {
      datadogRum.addError(new Error(`Benefits API returned status 500 for session ID ${sessionId}.`), ErrorSource.NETWORK);
    }
    yield put(commonBenefitsFailed(e));
  }

  if (route === "OmniAssist") {
    yield * logJacadaEvent({ memberId, partnerCode, promoCode, sessionId, phone, authenticated, equpmnt_clss_id });
  }

}

function* manageCommonData({benefitsData, omniStreamlined}) {

  if (!!benefitsData?.callbackNumber) {
    localStorage.setItem('callbackNumber', benefitsData.callbackNumber)
  }

  // MILES AND DOLLARS BASED MAPPING update serviceDetails for fortegra / VAS / A08
  if(benefitsData.status?.toUpperCase() === CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO || !!omniStreamlined) {
    const serviceCost = benefitsData.experience?.serviceCost || {};
    yield put(updateServiceDetails(serviceCost));
  }

  if(!!omniStreamlined) {
    if(!!benefitsData?.memberId) {
      localStorage.setItem('memberId', benefitsData.memberId);
    }

    if(!!benefitsData?.callId) {
      localStorage.setItem('benefitSession', benefitsData.callId);
      yield put(updateSessionId(benefitsData.callId));
    }

    if(!!benefitsData?.partnerMemberId) {
      yield put(updatePartnerMemberId(benefitsData.partnerMemberId));
    }

    if(!!benefitsData?.promoCode) {
      yield put(updatePromoCode(benefitsData.promoCode));
    }

    if(!!benefitsData?.usageId) {
      localStorage.setItem('usageId', benefitsData.usageId);
      yield put(updateUsageId(benefitsData.usageId));
    }

    if(!!benefitsData?.businessName) {
      yield put(updateBusinessName(benefitsData.businessName));
    }
    if (!!benefitsData?.rentalDetail) {
      yield put(updateRentalDetail(benefitsData.rentalDetail))
    }
  }
}

function* manageBenefitData({benefitsData, partnerDetails, sessionId, type, phone}) {
  const omniStreamlined = yield select(getOmniStreamlinedFlag)
  yield * manageCommonData({benefitsData, omniStreamlined});

  let { partnerCode, appId } = partnerDetails;

  if (partnerCode !== benefitsData.partnerCode) {
    partnerCode = benefitsData.partnerCode;
  }

  const outOfClaims = benefitsData.experience?.claimsExhausted;

  if (outOfClaims) {
    yield all([
      put(updatePpuFlag("Y")),
      put(updatePartnerDetailsExperience(benefitsData.experience)),
      logAndReturnOutOfClaims(benefitsData, omniStreamlined)
    ])
  } else {
    yield all([
      put(updatePpuFlag("N")),
      put(updateLoggedInFlag("Y")),
      put(updatePartnerDetailsExperience(benefitsData.experience)),
      logAndReturnSuccess({
        sessionId,
        responseBody: benefitsData,
        partnerCode,
        appId,
        type,
        phone
      })
    ])
  }
}

export function* benefits(action) {
  const commonBenefits = yield select(getCommonBenefits);
  const partnerDetails = yield select(getPartnerDetails);
  const serviceRequestPayload = yield select(getServiceRequestPayload);
  const serviceTransition = yield select(getServiceTransition);
  const omniStreamlined = yield select(getOmniStreamlinedFlag);
  let {
    partnerCode,
    route,
    appId,
    experience: { towTriageEnabled },
  } = partnerDetails;

  let lookupType = action.payload.lookupType;
  const details = action.payload.details;
  let partnerMemberId = action.payload.memberId || commonBenefits.memberId;
  let vin = action.payload.vin || commonBenefits.vin;
  let primaryLookup = partnerDetails.experience?.lookupMethods?.components.primary;
  if (!vin) {
    vin = action.payload.formdata?.vin;
  }
  if (primaryLookup === CONSTANTS.MOTOR_NUMBER_LOOKUP && !partnerMemberId) {
    partnerMemberId = action.payload?.rentalAgreementNumber;
    lookupType = 'rentalAgreementLookup';
  }

  let phone = serviceRequestPayload.serviceRequest.contactNumber;
  let callId = serviceRequestPayload.sessionId;
  let usageId = localStorage.getItem('usageId') || serviceRequestPayload.usageId;

  let tollFreeNumber = localStorage.getItem("tollFreeNumber");
  let jobId = localStorage.getItem("jobId");
  let lastName = action.payload?.lastName || details?.lastName;
  let firstName = details?.firstName;

  let authenticated = "N";
  let completeResponse;
  let retryBenefits = true;
  let retryBenefitsCounter = 0;
  try {
    const options = {
      partnerMemberId,
      partnerCode,
      appId,
      details,
      phoneNumber: phone,
      tollFreeNumber,
      lookupType,
      jobId,
      vin,
      callId,
      usageId,
      guestFlow: action.payload.guestFlow,
      lastName,
      searchCallId: action.payload.searchCallId
    };

    let { callId: tempCallId = "empty", searchCallId = "empty" } = options;
    if (tempCallId.includes("-") || searchCallId.includes("-")) {
      let tollFreeNumber = localStorage.getItem('tollFreeNumber');
      localStorage.clear();
      localStorage.setItem("tollFreeNumber", tollFreeNumber);

      let route = window.location.pathname.split("/")[1];
      let partner = window.location.pathname.split("/")[2];
      let url;
      if(route === "benefits") {
        url = generatePathWithSearchParams(`/${route}/${partner}/memberlookup`);
      } else {
        url = generatePathWithSearchParams(`/${route}/${partner}/lookup`);
      }

      sessionStorage.setItem("invalid_session", "true");
      yield call([action.history, action.history.push], url);
      datadogRum.addAction("CommonBenefitsSaga - benefits - Recovered from SessionID as UUID.");
      return;
    }

    //TODO Add search Rescue check based off what??
    let memberId = action.payload.memberId || commonBenefits.memberId;
    let callbackNumber = phone;
    let membershipNumber = memberId;
    let vinNumber = vin;
    const denyListRequest = {
      firstName,
      lastName,
      callbackNumber,
      membershipNumber,
      vinNumber
    }
    if(!isEmpty(denyListRequest) && JJ_AND_AMC.includes(partnerCode)) {
      denyListRequest.partnerCode = partnerCode;
      const denyResponse = yield call(fraudMonitor, denyListRequest);
      if(denyResponse?.success && denyResponse?.body?.statusText === 'DENY' ){
       yield put(commonBenefitsFailed({
            message: CONSTANTS.SERVICE_REQUEST_DENIED
          }));
          yield put(updateServiceTransition(
          {
            errorMessageContent: UNABLE_TO_PROCESS_REQUEST,
            declineType: CONSTANTS.SERVICE_REQUEST_DENIED
          }));
      }
    }
    if (memberId) {
      const searchRescueResponse = yield call(searchRescue, partnerCode, appId, lookupType, memberId);
      if (searchRescueResponse.status === 200) {
        const { usageId = "", duplicateRescueIronDoor = {}} = searchRescueResponse.data
        experimentalMonitor(duplicateRescueIronDoor)(
          "Denominator",
          { usageId },
          "duplicateRescueIronDoor"
        );
        yield put(updateDuplicateRescueIsActive({
          usageId,
          duplicateRescueIronDoor
        }))
        return;
      }
    }

    completeResponse = yield call(fetchBenefits, options, appId, partnerCode);
    const benefitsData = completeResponse?.data;
    //oneapp specific logic
    if (action.payload.searchCallId && benefitsData) {
      memberId = benefitsData.partnerMemberId;
      const searchRescueResponse = yield call(searchRescue, partnerCode, appId, "policyNumber", memberId);
      if (searchRescueResponse.status === 200) {
        const { usageId = "", duplicateRescueIronDoor = {}} = searchRescueResponse.data
        experimentalMonitor(duplicateRescueIronDoor)(
          "Denominator",
          { usageId },
          "duplicateRescueIronDoor"
        );
        yield put(updateDuplicateRescueIsActive({
          usageId,
          duplicateRescueIronDoor
        }))
        return;
      }
    }
    while (retryBenefits && retryBenefitsCounter < 3) {
      if (completeResponse?.status === 200) {
        retryBenefits = false;
        if (
          !!serviceTransition?.errorMessageContent ||
          !!serviceTransition?.declineType ||
          !!serviceTransition?.serviceType
        ) {
          yield put(
            updateServiceTransition({
              errorMessageContent: {},
              declineType: "",
              serviceType: "",
            })
          );
        }
        const benefitStatus = benefitsData?.status?.toUpperCase();
        datadogRum.addAction(`Benefits Status ${benefitStatus} for partner ${partnerCode} during ${lookupType} with usageId ${benefitsData.usageId} and callId ${benefitsData.callId}`,
          {callId: benefitsData.callId,
          usageId:benefitsData.usageId,
          benefitStatus:benefitStatus,
          partnerCode:benefitsData.partnerCode,
          lookupType:lookupType});
        if (towTriageEnabled) {
          if (!!benefitsData?.tollFreeNumber) {
            localStorage.setItem("tollFreeNumber", benefitsData.tollFreeNumber);
          } else {
            datadogRum.addAction(
              "Benefits Search did not return Toll Free Number from Job Id and ANI",
              { jobId, incomingNumber: phone }
            );
          }
        }
        if(serviceRequestPayload?.promoCode == null || benefitsData?.promoCode !== serviceRequestPayload.promoCode) {
          yield put(updatePromoCode(benefitsData?.promoCode));
        }
        switch (benefitStatus) {
          case "GUEST":
            yield all([
              put(updatePpuFlag("Y")),
              put(
                updateSessionId(benefitsData?.callId)
              ),
              put(
                updateUsageId(benefitsData?.usageId)
              ),
              put(
                noCommonBenefits({
                  message: "",
                  response: benefitsData,
                })
              ),
            ]);
            yield* manageCommonData({ benefitsData, omniStreamlined });
            return;
          case VAS_DG3090:
          case VAS_DG3180:
          case VAS_DG4090:
          case VAS_DG4180:
          case MEMBER_NOT_FOUND_VAS:
            yield all([
              put(
                updateServiceTransition({
                  errorMessageContent: MEMBER_NOT_FOUND,
                })
              ),
              put(
                noCommonBenefits({
                  message: CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE,
                  response: {},
                })
              ),
            ]);
            return;
          case MULTIPLE_MEMBERS_RETURNED:
            yield all([
              put(
                updateSessionId(benefitsData?.callId)
              ),
              put(
                updateUsageId(benefitsData?.usageId)
              ),
              put(
                updateServiceTransition({
                  errorMessageContent: MEMBER_NOT_FOUND,
                })
              ),
              put(
                noCommonBenefits({
                  message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
                  response: {},
                })
              ),
            ]);
            return;
          case MATCH_FAILURE:
            yield all([
              put(
                updateSessionId(benefitsData?.callId)
              ),
              put(
                updateUsageId(benefitsData?.usageId)
              ),
              put(updatePpuFlag("Y")),
              put(
                updateServiceTransition({
                  errorMessageContent: MEMBER_NOT_FOUND,
                })
              ),
              put(
                noCommonBenefits({
                  message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
                  response: benefitsData
                })
              )
            ]);
            return;
          case CONTRACT_CANCELLED:
          case CONTRACT_EXPIRED:
          case CONTRACT_NOT_ACTIVE_YET:
          case INACTIVE:
          case BENEFITS_USED_UP:
          case REIMBURSEMENT:
            yield* manageCommonData({ benefitsData, omniStreamlined });
            yield all([
              put(updatePpuFlag("Y")),
              put(updatePartnerDetailsExperience(benefitsData.experience)),
              logAndReturnOutOfClaims(benefitsData, omniStreamlined),
            ]);
            break;
          case MEMBER_NOT_FOUND_STATUS:
            let errorMessage = CONSTANTS.MEMBER_NOT_FOUND_MESSAGE;
            if (lookupType === CONSTANTS.MOTOR_NUMBER_LOOKUP || lookupType === CONSTANTS.RENTAL_AGREEMENT_LOOKUP) {
              errorMessage = CONSTANTS.INVALID_MVA_NUMBER;
            }
            yield* manageCommonData({ benefitsData, omniStreamlined });
            if (benefitsData?.experience) {
              yield put(updatePartnerDetailsExperience(benefitsData.experience));
            }
            yield all([
              put(updatePpuFlag("Y")),
              put(
                noCommonBenefits({
                  message: errorMessage,
                  response: benefitsData,
                })
              ),,
            ]);
            break;
          case WAIT_PERIOD_STATUS:
            yield* manageCommonData({ benefitsData, omniStreamlined });
            if (benefitsData?.experience) {
              yield put(updatePartnerDetailsExperience(benefitsData.experience));
            }
            yield all([
              put(updatePpuFlag("Y")),
              put(noCommonBenefits({
                message: CONSTANTS.WAIT_PERIOD,
                response: benefitsData
              }))
            ]);
            break;
          case CONSTANTS.ACTIVE:
          case CONSTANTS.MUST_DISPLAY_TRIAGE_STEPS:
          case CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO:
            authenticated = "Y";
            yield* manageBenefitData({
              benefitsData: benefitsData,
              partnerDetails,
              sessionId: benefitsData.callId,
              type: "omni-client",
              phone,
            });
            break;
          case CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS:
            yield all([
              put(updatePartnerDetailsExperience(benefitsData.experience)),
              put(
                updateServiceTransition({
                  declineType: CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS,
                })
              ),
              put(
                noCommonBenefits({
                  message: CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS,
                  response: benefitsData,
                })
              ),
            ]);
            break;
          default:
            yield all([
              put(
                updateServiceTransition({
                  errorMessageContent: MEMBER_NOT_FOUND,
                })
              ),
              put(
                noCommonBenefits({
                  message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
                  response: {},
                })
              ),
            ]);
            return;
        }

        if (route === "OmniAssist") {
          yield* logJacadaEvent({
            memberId: partnerMemberId,
            partnerCode,
            promoCode: benefitsData.promoCode,
            sessionId: benefitsData.callId,
            phone,
            authenticated,
            equpmnt_clss_id: "",
          });
        }
        return;
      } else if (isCreatedNoContentStatus(completeResponse?.status)) {
        datadogRum.addAction(
          `Omni Search Lookup Not Successful for ${lookupType} reason ${completeResponse?.data?.message}`
        );
        yield all([
          put(
            noCommonBenefits({
              message: CONSTANTS.MEMBER_NOT_FOUND_MESSAGE,
              response: {},
            })
          ),
        ]);
        retryBenefits = false;
        return;
      } else if (is5xxServerError(completeResponse?.status)) {
        datadogRum.addAction(
          `500 Omni Search Lookup Not Successful for ${lookupType} reason ${completeResponse?.data}`
        );
        yield put({ type: COMMON_BENEFITS_FAILED });
        retryBenefits = false;
        return;
      }
      retryBenefitsCounter++;
      yield delay(1000); // wait 1 second before retry logic
    }
  } catch (e) {
    if (is4xxClientError(e?.response?.status)) {
      datadogRum.addError("Omni Search API returned status 400");
    } else {
      datadogRum.addError(
        `Omni Search API returned error status ${e?.response?.status}`
      );
    }
    yield put(commonBenefitsFailed(e));
  }
}

export function* watchCommonBenefitsSaga() {
  yield takeEvery(COMMON_BENEFITS_START, commonBenefits);
}

export function* watchBenefitLookupSaga() {
  yield takeEvery(BENEFITS_LOOKUP_START, benefits);
}
