import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Home from './screens/Home/';
import Location from './screens/Location/';
import DisablementLocation from './screens/DisablementLocation';
import HelpStuck from './screens/HelpStuck';
import Feedback from './screens/Feedback';
import SiteDown from './screens/SiteDown';
import Tracker from './screens/Tracker';
import Triage from './screens/Triage';
import Payment from "./screens/Payment";
import { IncidentReportFormContainer } from './screens/IncidentReport';
import ServiceTransition from "./screens/ServiceTransition";
import Lookup from "./screens/Lookup";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import {datadogRum} from "@datadog/browser-rum";
import CONSTANTS, {
  DD_PROD_ENV,
  DD_PROD_ID,
  DD_PROD_TOKEN,
  DD_QA_ENV,
  DD_QA_ID,
  DD_QA_TOKEN
} from "./app-consts/appConstants";
import DefaultLayout from './components/defaultLayout';
import CommonLookup from "./screens/CommonLookup";
import RescueTracker from "./screens/RescueTracker";
import { AddVehicle, VehicleSelect } from './screens';
import {LocaleProvider} from "./contexts/LocaleContext";
import {connect} from 'react-redux';
import TriageSteps from './screens/TriageSteps';
import ScheduleService from './screens/ScheduleService';
import ServiceTime from './screens/ServiceTime';
import AppointmentConfirmation from './screens/AppointmentConfirmation';
import { getCommonBenefits, getDuplicateRescue } from './selector';
import DuplicateRescue from './screens/DuplicateRescue';
import { generatePath } from './utilities/RedirectUtils';
import withSession from './hoc/withSession';

export class App extends React.Component {
  constructor(props) {
    super(props);
    const runningLocally = window.location.href.includes('localhost')
              || window.location.href.includes('undefined')
    this.state = {
      appClass: "",
      datadogIsInitialized: runningLocally
    };
  }

  componentDidMount() {
    const route = this.props.location.pathname.split('/')[1];
    if(!sessionStorage.getItem('route')) {
      sessionStorage.setItem( 'route', route); // Store the route for later use
    }
    this.checkPath();

    let environment = '';
    let env = '';
    let appId = '';
    let clientToken = '';
    if(!this.state.datadogIsInitialized) {
      if (window.location.host === 'roadside.allstate.com'
          || window.location.host === 'www.getroadsideassistance.com') {
        environment = DD_PROD_ENV;
        appId = DD_PROD_ID;
        clientToken = DD_PROD_TOKEN;
        env = "prod";
      } else {
        environment = DD_QA_ENV;
        appId = DD_QA_ID;
        clientToken = DD_QA_TOKEN;
        env = "nonprod";
      }
      datadogRum.init({
        applicationId: appId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: environment,
        sampleRate: 100,
        version: '04-08-2025-release',
        trackInteractions: true,
        trackFrustrations: true,
        useCrossSiteSessionCookie: true,
        env: env
      });
      datadogRum.addRumGlobalContext('barometerit', '0418000019QP');
      window.datadogRum = datadogRum;
      window.DD_RUM.startSessionReplayRecording();
      this.setState({
        datadogIsInitialized: true
      })
    }
    this.redirectToDuplicateRescuePage();
  }

  componentDidUpdate() {
    this.checkPath();
    this.redirectToDuplicateRescuePage();
  }

  checkPath = () => {
    const path = this.props.location.pathname;
    const { appClass } = this.state
    if (this.props.currentLocation?.locationPromptClicked && path.includes("location")) {
      if (appClass !== "fixed") {
        this.setState({
          appClass: "fixed"
        });
      }
    } else {
      if (appClass !== "") {
        this.setState({
          appClass: ""
        });
      }
    }
  };

  redirectToDuplicateRescuePage = () => {
    const { history, location } = this.props
    const path = location.pathname;
    if(this.determineDuplicateRescueIronDoor() && !path.includes("duplicaterescue")){
      history.push(generatePath(`duplicaterescue`));
    }
  }

  determineDuplicateRescueIronDoor = () => {
    const { duplicateRescue } = this.props;
    if (duplicateRescue?.duplicateRescueIronDoor?.enabled) {
      return true;
    }
    return false;
  }

  render() {
    const {appClass} = this.state;
    return (
      <div className={`App ${appClass}`}>
        <LocaleProvider>
          <ErrorBoundary key={this.props.location.pathname}>
            <DefaultLayout
              isRescueTrackerStandalone={window.location.pathname.includes('/wd/')}
              hideMenu={window.location.pathname.split("/")[3] === "tracker"
                || window.location.pathname.split("/")[3] === "feedback"
                || window.location.pathname.split("/")[1] === "appointment-confirmation"
                || this.determineDuplicateRescueIronDoor()
                || this.props?.commonBenefits?.errorMessage === CONSTANTS.SERVICE_REQUEST_DENIED
            }
            >
              <Route exact path="/"><Redirect to="/member/AMC/memberlookup"/>
              </Route>
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/memberlookup"
                component={CommonLookup}
              />
              <Route
                exact path="/:usertype(member)/:partnercode"
                component={Lookup}
              />
              <Route
                path="/:usertype(OmniAssist|member)/:partnercode/lookup"
                component={Lookup}
              />
              <Route
                exact path="/:usertype(OmniAssist|member|ppu|benefits)/"
                component={Home}
              />
              <Route
                exact path="/:usertype(OmniAssist|ppu|benefits)/:partnercode"
                component={Home}
              />
              <Route
                exact
                path="/:usertype(OmniAssist|member|benefits)/:partnercode/home"
                component={Home}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/triage"
                component={Triage}
              />
              <Route
                path="/:usertype(OmniAssist|member)/:partnercode/triage-steps"
                component={TriageSteps}
              />
              <Route
                path="/:usertype(OmniAssist|member)/:partnercode/incident-report"
                component={IncidentReportFormContainer}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/help/:type"
                component={HelpStuck}
              />
              <Route
                path="/:usertype(OmniAssist|member|benefits)/:partnercode/service-time"
                component={ServiceTime}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/DisablementLocation"
                component={DisablementLocation}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/location"
                render={props => (
                  <Location {...props} />
                )}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/confirm-vehicle"
                component={VehicleSelect}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/add-vehicle"
                component={AddVehicle}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/payment"
                component={Payment}
              />
              {/* Schedule Services page dark release changes */}
              <Route
                path="/appointment-confirmation"
                component={AppointmentConfirmation}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/tracker/:sessionId/:callId/:requestId/:usageId/:language?"
                component={Tracker}
              />
              <Route
                path="/wd/:linkType(l|f)/:partnerCode/:uuid/:usageId/:language?"
                render={props => (
                  <RescueTracker {...props} isRescueTrackerStandalone={true}/>
                )}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/feedback"
                component={Feedback}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/ServiceTransition"
                component={ServiceTransition}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits|wd)/:partnercode/sitedown"
                render={props => (
                  <SiteDown {...props}/>
                )}
              />
              <Route
                path="/:usertype(OmniAssist|member|benefits)/:partnercode/ScheduleService"
                component={ScheduleService}
              />
              <Route
                path="/:usertype(OmniAssist|member|ppu|benefits)/:partnercode/duplicaterescue"
                component={DuplicateRescue}
              />
            </DefaultLayout>
          </ErrorBoundary>
        </LocaleProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLocation: state.currentLocation,
    commonBenefits: getCommonBenefits(state),
    duplicateRescue: getDuplicateRescue(state),
  };
}

export default connect(mapStateToProps)(withSession(App));
